import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Block } from "baseui/block"
import { Cell } from "baseui/layout-grid"
import Fade from "react-reveal/Fade"
import Img from "gatsby-image"
import Image from "../../components/Image"
import { Title, Description } from "../../components/typography"
import FlushedGrid from "../../components/FlushedGrid"
import Container from "../../components/UI/Container"

const HowItWorks = () => {
  const Data = useStaticQuery(graphql`
    query {
      imgixImage(url: "/uploads/tm_flowchart.jpg") {
        fluid(maxWidth: 1600) {
          ...GatsbyImgixFluid
        }
      }
    }
  `)
  return (
    <Block paddingTop="60px" paddingBottom="60px" id="tm-feature">
      <Container>
        <FlushedGrid gridGutters={[0, 56]} gridGaps={48}>
          <Cell span={[12]}>
            <Block
              display="flex"
              flexDirection="column"
              marginLeft={["18px", "10%", "20%", 0]}
            >
              <Title
                style={{
                  textAlign: "center",
                }}
              >
                How It Works
              </Title>
              <Description
                style={{
                  textAlign: "center",
                }}
                paddingBottom="30px"
              >
                We integrate with your payment gateway to screen transactions
                when they are being made. Suspicious transactions are
                immediately flagged out for internal review.
              </Description>
            </Block>
          </Cell>
          <Cell span={[12]}>
            <Block alignItems="center" height="100%" maxWidth="1600px">
              <Img
                fluid={{ ...Data.imgixImage.fluid }}
                style={{ width: "100%" }}
                alt="How It Works Flowchart"
              />
            </Block>
          </Cell>
        </FlushedGrid>
      </Container>
    </Block>
  )
}

export default HowItWorks
