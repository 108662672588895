import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { buildFluidImageData } from "@imgix/gatsby"
import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"
import Slide from "react-reveal/Slide"
import { Block } from "baseui/block"
import { Cell } from "baseui/layout-grid"
import {
  Title,
  SubTitle,
  ItemTitle,
  ItemDescription,
} from "../../components/typography"
import Text from "../../components/Text"
import FlushedGrid from "../../components/FlushedGrid"
import Container from "../../components/UI/Container"

import { TimelineItem, TimelineDot } from "./timeline.style"

const CDDFeature = ({ indexStyle }) => {
  const data = useStaticQuery(graphql`
    query {
      cryptoJson {
        CDD_FEATURES {
          title
          description
        }
      }
      imgixImage(url: "/uploads/risk-reporting.jpg") {
        fluid(maxWidth: 800) {
          ...GatsbyImgixFluid
        }
      }
    }
  `)

  return (
    <Block paddingTop="80px" paddingBottom="80px" id="cdd-feature">
      <Container>
        <Block marginBottom={["40px", "40px", "40px", "60px"]}>
          <SubTitle textAlign="center">How Cylynx Can Help</SubTitle>
          <Title textAlign="center">
            Improve accuracy, efficiency, and customer experience
          </Title>
        </Block>
        <FlushedGrid gridGutters={[0, 56]} gridGaps={48}>
          <Cell span={[12, 6]}>
            <Block marginTop={["18px", "42px"]}>
              <Fade bottom>
                <Img
                  fluid={{ ...data.imgixImage.fluid }}
                  style={{ width: "100%", height: "100%" }}
                  alt="Risk Reports"
                />
              </Fade>
            </Block>
          </Cell>
          <Cell span={[12, 6]}>
            <Block>
              {data.cryptoJson.CDD_FEATURES.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TimelineItem key={`item-${index}`}>
                  <Slide bottom>
                    <Text
                      as="span"
                      content={item.index || `0${index + 1}`}
                      {...indexStyle}
                    />
                  </Slide>
                  <Block marginLeft="30px">
                    <Slide bottom delay={100}>
                      <ItemTitle>{item.title}</ItemTitle>
                    </Slide>
                    <Slide bottom delay={200}>
                      <ItemDescription>{item.description}</ItemDescription>
                    </Slide>
                  </Block>
                  <TimelineDot />
                </TimelineItem>
              ))}
            </Block>
          </Cell>
        </FlushedGrid>
      </Container>
    </Block>
  )
}

CDDFeature.propTypes = {
  indexStyle: PropTypes.object,
}

CDDFeature.defaultProps = {
  indexStyle: {
    fontSize: ["36px", "42px", "52px", "56px", "72px"],
    fontWeight: "300",
    color: "#eaebec",
    display: "block",
    lineHeight: "1",
    mb: "0",
  },
}

export default CDDFeature
