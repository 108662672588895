import React from "react"

import { Block } from "baseui/block"
import { SEOPage } from "../components/SEO"
import Layout from "../components/layout"
import BannerPlain from "../containers/BannerPlainSection"
import CDDFeature from "../containers/BlocklynxProductPage/CDDFeature"
import TMFeature from "../containers/BlocklynxProductPage/TMFeature"
import HowItWorks from "../containers/BlocklynxProductPage/HowItWorks"
import TMMachineLearning from "../containers/BlocklynxProductPage/TMMachineLearning"
import TrySection from "../containers/BlocklynxProductPage/TrySection"
import { Newsletter } from "../containers/Newsletter"
import BannerBG from "../images/banner-blocklynx.png"

export default () => {
  return (
    <Layout>
      <SEOPage
        title="Pre-Transaction Monitoring for Compliance"
        description="Move beyond identity verification - prioritise risk cases and improving screening efficiency"
      />
      <BannerPlain
        title="Pre-Transaction Monitoring for Compliance"
        subTitle="Move beyond identity verification - prioritise risk cases and improving screening efficiency"
        background={BannerBG}
        backgroundSize="cover"
        backgroundColor="#0C6B58"
      />
      <CDDFeature />
      <TMFeature />
      <HowItWorks />
      <TMMachineLearning />
      <TrySection />
      <Block marginBottom="150px" />
      <Newsletter ButtonText="Download the Infographic Report" />
    </Layout>
  )
}
