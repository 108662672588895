import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Block } from "baseui/block"
import { Cell } from "baseui/layout-grid"
import Fade from "react-reveal/Fade"
import Image from "../../components/Image"
import {
  Title,
  Description,
  ItemTitle,
  ItemDescription,
} from "../../components/typography"
import FlushedGrid from "../../components/FlushedGrid"
import Container from "../../components/UI/Container"

const TMFeature = () => {
  const data = useStaticQuery(graphql`
    query {
      cryptoJson {
        TM_FEATURE {
          title
          description
          icon {
            publicURL
          }
        }
      }
    }
  `)

  return (
    <Block paddingTop="60px" paddingBottom="60px" id="tm-feature">
      <Container>
        <FlushedGrid gridGutters={[0, 56]} gridGaps={48}>
          <Cell span={[12]}>
            <Block
              display="flex"
              flexDirection="column"
              marginLeft={["18px", "10%", "20%", 0]}
            >
              <Title
                style={{
                  textAlign: "center",
                }}
              >
                Transaction Monitoring Features
              </Title>
              <Description
                style={{
                  textAlign: "center",
                }}
                paddingBottom="30px"
              >
                Screen transactions through the platform to uncover hidden risks
                and maintain a full compliance trail
              </Description>
              <FlushedGrid gridGaps={[28, 36]}>
                {data.cryptoJson.TM_FEATURE.map(item => (
                  <Cell key={`feature-${item.title}`} span={4}>
                    <Fade up>
                      <Block
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Image
                          src={item.icon.publicURL}
                          alt={item.title}
                          style={{
                            width: "50px",
                            height: "50px",
                            marginBottom: "20px",
                          }}
                        />
                        {item.path && (
                          <Link to={item.path}>
                            <ItemTitle
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {item.title}
                            </ItemTitle>
                            <ItemDescription
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {item.description}
                            </ItemDescription>
                          </Link>
                        )}
                        {!item.path && (
                          <Block>
                            <ItemTitle
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {item.title}
                            </ItemTitle>
                            <ItemDescription
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {item.description}
                            </ItemDescription>
                          </Block>
                        )}
                      </Block>
                    </Fade>
                  </Cell>
                ))}
              </FlushedGrid>
            </Block>
          </Cell>
        </FlushedGrid>
      </Container>
    </Block>
  )
}

export default TMFeature
