import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Img from "gatsby-image"
import { buildFluidImageData } from "@imgix/gatsby"
import Fade from "react-reveal"
import { Button } from "baseui/button"
import { Block } from "baseui/block"
import { Cell } from "baseui/layout-grid"
import { Title, SubTitle, Description } from "../../components/typography"
import Container from "../../components/UI/Container"
import FlushedGrid from "../../components/FlushedGrid"

const TrySection = () => {
  const data = useStaticQuery(graphql`
    query {
      imgixImage(url: "/uploads/investigate-resize.png") {
        fluid(maxWidth: 800) {
          ...GatsbyImgixFluid
        }
      }
    }
  `)
  return (
    <Block paddingTop="100px" paddingBottom="100px" id="control">
      <Container>
        <FlushedGrid gridGutters={[0, 56]} gridGaps={48}>
          <Cell span={[12, 6]}>
            <Block
              display="flex"
              alignItems="center"
              height="100%"
              maxWidth="600px"
              marginLeft={["18px", "10%", "20%", 0]}
            >
              <Img
                fluid={{ ...data.imgixImage.fluid }}
                style={{ width: "100%" }}
                alt="Blocklynx Image"
              />
            </Block>
          </Cell>
          <Cell span={[12, 6]}>
            <Block
              display="flex"
              flexDirection="column"
              marginLeft={["18px", "10%", "20%", 0]}
            >
              <SubTitle>Beta trial now available</SubTitle>
              <Title>Interested in checking out the platform?</Title>
              <Fade up>
                <span>
                  <Description paddingBottom="20px">
                    Request a demo to indicate your interest and our team will
                    get back to you with an exclusive trial account
                  </Description>
                  <Block
                    position="relative"
                    marginTop="20px"
                    marginBottom="60px"
                  >
                    <AnchorLink to="/contact-us">
                      <Button>Let's get started</Button>
                      <Button
                        overrides={{
                          BaseButton: {
                            style: {
                              position: "absolute",
                              top: "37px",
                              height: "34px",
                              minWidth: "120px",
                              paddingTop: "10px",
                              left: "123px",
                              borderTopLeftRadius: "3px",
                              borderTopRightRadius: "3px",
                              borderBottomLeftRadius: "3px",
                              borderBottomRightRadius: "3px",
                              backgroundColor: "#14e3ba",
                              ":hover": {
                                boxShadow: "0px 9px 20px -5px #3EBD93",
                                backgroundColor: "#14e3ba",
                              },
                            },
                          },
                        }}
                      >
                        Free trial
                      </Button>
                    </AnchorLink>
                  </Block>
                </span>
              </Fade>
            </Block>
          </Cell>
        </FlushedGrid>
      </Container>
    </Block>
  )
}

export default TrySection
